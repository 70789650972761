import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "./box"
import Button from "./button"
import Image from "./image"
import Grid from "./grid"
import Heading from "./heading"
import Text from "./text"
import Stack from "./stack"

const NewsletterBanner = () => {
  const data = useStaticQuery(graphql`
    {
      posteingang: file(
        relativePath: { eq: "newsletter/posteingang-square.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 500, layout: CONSTRAINED)
        }
      }
      posteingangSmall: file(
        relativePath: { eq: "newsletter/posteingang.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 640, height: 300, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Box bg="muted">
      <Grid
        columns={[1, 1, 2]}
        space={0}
        reverse={[false, false, true]}
        alignY="center"
      >
        <Box>
          <Box display={["block", "block", "none"]}>
            <Image
              image={data.posteingangSmall.childImageSharp.gatsbyImageData}
              alt="Auf einem Laptop ist ein Email-Programm geöffnet, das den Newsletter des Hölderlinturms zeigt."
            />
          </Box>
          <Box display={["none", "none", "block"]}>
            <Image
              image={data.posteingang.childImageSharp.gatsbyImageData}
              alt="Auf einem Laptop ist ein Email-Programm geöffnet, das den Newsletter des Hölderlinturms zeigt."
            />
          </Box>
        </Box>

        <Box p={[6, 8, 12]}>
          <Stack space={6}>
            <Stack space={2}>
              <Heading as="h2" color="whisper">
                Unser Newsletter
              </Heading>
              <Heading as="strong" level={3}>
                Neues aus dem Hölderlinturm
              </Heading>
            </Stack>
            <Text size={[2, 2, 3]}>
              Sie wollen regelmäßig über das aktuelle Programm des
              Hölderlinturms informiert werden? Dann melden Sie sich für unseren
              Newsletter an:{" "}
            </Text>
            <Button to="/newsletter">Jetzt anmelden</Button>
          </Stack>
        </Box>
      </Grid>
    </Box>
  )
}

export default NewsletterBanner
